<template>
  <v-container
    class="align-stretch layout-container"
    style="height: calc(100% - 150px);"
  >
    <BaseHeader
      title="Ihre persönliche Erfolgsanalyse"
      :style-variant="11"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          Das Dashboard zeigt Ihnen die wichtigsten Kennzahlen und Handlungsempfehlungen.
        </p>
      </template>
    </BaseHeader>

    <v-card
      v-if="url"
      outlined
      class="mt-2 h-full"
    >
      <iframe
        class="h-full w-full"
        :src="url"
        frameborder="0"
        allowfullscreen
      />
    </v-card>
    <v-card
      v-else
      outlined
      class="mt-2"
    >
      <NoContentScreen title="Aktuell ist noch keine Erfolgsanalyse für Sie hinterlegt." />
    </v-card>
  </v-container>
</template>

<script>
import STATISTIC_DASHBOARD from '@/queries/StatisticDashboard.gql'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/performanceReport/HeaderDesign.vue'
import NoContentScreen from '@/components/NoContentScreen.vue'

export default {
  components: {
    BaseHeader,
    HeaderDesign,
    NoContentScreen
  },
  apollo: {
    url: {
      query: STATISTIC_DASHBOARD,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
