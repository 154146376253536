<template>
  <div>
    <v-row v-if="useIubenda">
      <v-col cols="12">
        <AllInOneLegalCard />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        v-for="topic in topics"
        :key="topic.slug"
        cols="12"
        md="6"
        lg="4"
      >
        <legal-card
          :item="topic"
          :is-external-link="topic.isExternalLink"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { appBranding, iubenda } from '@/lib/features'
import featureMixin from '@/mixins/feature'
import LegalCard from './LegalCard.vue'
import AllInOneLegalCard from './AllInOneLegalCard.vue'

const TOPIC = [
  {
    slug: 'imprint',
    title: 'legal.topic.imprint.title',
    text: 'legal.topic.imprint.text',
    route: appBranding.config.imprintUrl,
    target: '_blank',
    linkText: 'legal.topic.imprint.link-text',
    icon: 'mdi-file-document-outline',
    isExternalLink: true
  },
  {
    slug: 'privacy',
    title: 'legal.topic.privacy.title',
    text: 'legal.topic.privacy.text',
    linkText: 'legal.topic.privacy.link-text',
    route: appBranding.config.dataPrivacyUrl,
    target: '_blank',
    icon: 'mdi-security',
    isExternalLink: appBranding.config.dataPrivacyUrl.startsWith('https')
  },
  {
    slug: 'license-agreement',
    title: 'legal.topic.license-agreement.title',
    text: 'legal.topic.license-agreement.text',
    linkText: 'legal.topic.license-agreement.link-text',
    route: 'license-agreement',
    icon: 'mdi-file-sign',
    isExternalLink: false
  }

]

export default {
  components: {
    LegalCard,
    AllInOneLegalCard
  },
  mixins: [featureMixin],

  data () {
    return {
      useIubenda: iubenda?.isActive
    }
  },

  computed: {
    topics () {
      const isLicenseAgreementFeatureActive = this.isFeatureActive(this.featureNames.LICENSE_AGREEMENT)
      if (!isLicenseAgreementFeatureActive) {
        return TOPIC.filter(topic => topic.slug !== 'license-agreement')
      } else {
        return TOPIC
      }
    }
  }
}
</script>
