
<template>
  <v-card
    outlined
    height="100%"
    class="d-flex flex-column"
  >
    <v-card-title class="my-md-4">
      <h4>
        <v-icon>mdi-file-document-outline</v-icon>
        {{ $t('legal.terms.title') }}
      </h4>
    </v-card-title>

    <v-card-text class="px-4 text-body-1">
      <p v-html="$t('legal.terms.text')" />
      <p>
        <DocumentLink type="terms" /><br>
        <a
          :href="imprintUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('legal.links.imprint') }}<v-icon
            class="ml-1 primary--text"
            size="14"
          >
            mdi-open-in-new
          </v-icon>
        </a>
      </p>
    </v-card-text>

    <v-card-title class="my-md-4">
      <h4>
        <v-icon>mdi-security</v-icon>
        {{ $t('legal.privacy.title') }}
      </h4>
    </v-card-title>

    <v-card-text class="px-4 text-body-1">
      <p v-html="$t('legal.privacy.text')" />
      <p>
        <DocumentLink type="privacyPolicy" /><br>
        <DocumentLink type="cookiePolicy" /><br>
        <ConsentPreferencesLink />
      </p>
    </v-card-text>

    <template v-if="showDpaSection">
      <v-card-title class="my-md-4">
        <h4>
          <v-icon>mdi-file-sign</v-icon>
          {{ $t('legal.dpa.title') }}
        </h4>
      </v-card-title>

      <v-card-text class="px-4 text-body-1">
        <p v-text="$t('legal.dpa.p1')" />
        <p v-text="$t('legal.dpa.p2')" />
        <p v-text="$t('legal.dpa.p3')" />
        <p>
          <!-- TODO the documents and links are not available yet -->
          <a href="#">{{ $t('legal.dpa.linkDpa') }}</a><br>
          <a href="#">{{ $t('legal.dpa.linkSubProcessors') }}</a><br>
          <a href="#">{{ $t('legal.dpa.linkToms') }}</a>
        </p>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import DocumentLink from '@/modules/iubenda/DocumentLink'
import ConsentPreferencesLink from '@/modules/iubenda/ConsentPreferencesLink'

export default {
  components: {
    DocumentLink,
    ConsentPreferencesLink
  },

  mixins: [brandingMixin],

  props: {
    showDpaSection: {
      type: Boolean,
      default: false
    }
  }
}
</script>
