<template>
  <v-container class="layout-container">
    <BaseHeader
      :title="$t('legal.title')"
      :style-variant="18"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('legal.subtitle') }}</p>
      </template>
    </BaseHeader>
    <v-col
      cols="12"
      class="pa-0"
    >
      <Legal />
    </v-col>
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import Legal from '@/modules/legal'
import HeaderDesign from '@/modules/legal/HeaderDesign.vue'

export default {
  components: {
    BaseHeader,
    Legal,
    HeaderDesign
  }
}
</script>
